import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row justify-content-center"
}
const _hoisted_2 = {
  key: 1,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_indicator = _resolveComponent("loading-indicator")!
  const _component_page_greeting = _resolveComponent("page-greeting")!
  const _component_course_card = _resolveComponent("course-card")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_loading_indicator, { class: "col" })
      ]))
    : (_ctx.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_page_greeting, {
            title: _ctx.title,
            message: _ctx.message
          }, null, 8, ["title", "message"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
            return (_openBlock(), _createElementBlock("div", {
              key: course.id,
              class: "col-lg-6 col-xl-4 d-flex"
            }, [
              _createVNode(_component_course_card, { course: course }, null, 8, ["course"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
}