
import { computed, defineComponent } from "vue";
import { AppActionTypes, useStore } from "@/store";

import CourseCardVue from "@/components/CourseCard.vue";
import PageGreetingVue from "@/components/PageGreeting.vue";
import { loadData } from "@/helpers";

export default defineComponent({
    components: {
        "course-card": CourseCardVue,
        "page-greeting": PageGreetingVue
    },
    setup() {
        const store = useStore();
        const { state, getters, dispatch } = store;

        const user = computed(() => state.user);
        const courses = computed(() => state.courseItems);

        loadData(store, async () => {
            await dispatch(AppActionTypes.getCourseItems);
        });

        // Page greeting
        const title = "Continue Learning 🗺️";
        const message = "";

        return {
            user,
            courses,
            loading: computed(() => getters.loading),
            title,
            message
        };
    }
});
